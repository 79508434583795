exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/Datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/Impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/Kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistungsspektrum-js": () => import("./../../../src/pages/Leistungsspektrum.js" /* webpackChunkName: "component---src-pages-leistungsspektrum-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/Partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-referenzen-js": () => import("./../../../src/pages/Referenzen.js" /* webpackChunkName: "component---src-pages-referenzen-js" */),
  "slice---src-components-background-js": () => import("./../../../src/components/Background.js" /* webpackChunkName: "slice---src-components-background-js" */)
}

